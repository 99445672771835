import React, { useState, useEffect } from 'react';
import imageCompression from 'browser-image-compression';
import { useParams } from 'react-router-dom';
import { client } from '../sanity/sanityClient';
import { v4 as uuidv4 } from 'uuid';
import './ProductForm.css';

const ProductForm = () => {
  const { userId } = useParams();
  const [productName, setProductName] = useState('');
  const [price, setPrice] = useState('');
  const [details, setDetails] = useState('');
  const [location, setLocation] = useState('');
  const [latitude, setLatitude] = useState(null); // State for latitude
  const [longitude, setLongitude] = useState(null); // State for longitude
  const [images, setImages] = useState([]); // For original previews
  const [optimizedImages, setOptimizedImages] = useState([]); // For optimized files
     const [categories] = useState([
          'clothingandfashion', 'electronics', 'kitchen', 'crops',  'fashion',
          'grocery',  'vehicles', "hotels", "sports",
        'foodandbeverages', 'homeandfurnitures', 'arts',  'medical', 
    ]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categoryId, setCategoryId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch Geolocation on Component Mount
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error fetching geolocation:", error.message);
          setErrorMessage("Unable to fetch geolocation. Please enable location services.");
        }
      );
    } else {
      setErrorMessage("Geolocation is not supported by your browser.");
    }
  }, []);


  useEffect(() => {
    const fetchCategoryId = async () => {
      if (selectedCategory) {
        try {
          const result = await client.fetch(
            `*[_type == $selectedCategory][0]{ _id }`,
            { selectedCategory }
          );
          if (result) {
            setCategoryId(result._id);
          } else {
            setErrorMessage(`Category "${selectedCategory}" not found.`);
          }
        } catch (error) {
          setErrorMessage('Error fetching category ID');
          console.error('Error fetching category ID:', error.message);
        }
      }
    };

    fetchCategoryId();
  }, [selectedCategory]);

  // Handle image compression and preview
  const handleImageChange = async (e) => {
  const files = Array.from(e.target.files);

  const compressAndConvertToWebP = async (file) => {
    const options = {
      maxSizeMB: 0.3, // Maximum file size (in MB)
      maxWidthOrHeight: 800, // Max dimensions
      useWebWorker: true, // Use web workers for faster processing
      fileType: 'image/webp', // Convert to WebP
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile; // Returns the compressed WebP file
    } catch (error) {
      console.error('Error during image compression:', error);
      throw new Error('Image compression failed');
    }
  };

  try {
    const optimizedFiles = await Promise.all(files.map((file) => compressAndConvertToWebP(file)));
    setImages(files); // Keep original images for previews
    setOptimizedImages(optimizedFiles); // Store optimized images for uploading
  } catch (error) {
    console.error('Image processing failed:', error);
    setErrorMessage('Failed to process images');
  }
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    if (!categoryId) {
      setErrorMessage('Select a valid category before adding a product.');
      setIsLoading(false);
      return;
    }

    try {
      const imageIds = await uploadImages(optimizedImages); // Use optimized images
      const slugValue = generateSlug(productName);

      const newProduct = await client.create({
        _type: selectedCategory,
        name: productName,
        slug: { _type: 'slug', current: slugValue },
        price: Number(price),
        details,
        user: userId,
        location,
         geolocation: {
          _type: 'geopoint',
          lat: latitude,
          lng: longitude,
        },
        image: imageIds.map((id) => ({
          _type: 'image',
          _key: uuidv4(),
          asset: { _type: 'reference', _ref: id },
        })),
      });

      setSuccessMessage('Product added successfully!');
      resetForm();
      console.log('Product created:', newProduct);
    } catch (error) {
      setErrorMessage('Failed to add the product. Please try again.');
      console.error('Error adding product:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const uploadImages = async (images) => {
    const imageIds = [];
    for (let image of images) {
      try {
        const asset = await client.assets.upload('image', image, { filename: image.name });
        imageIds.push(asset._id);
      } catch (error) {
        console.error('Image upload failed:', error.message);
        throw new Error('Image upload failed');
      }
    }
    return imageIds;
  };

  const resetForm = () => {
    setProductName('');
    setPrice('');
    setDetails('');
    setLocation('');
    setImages([]);
    setOptimizedImages([]);
    setSelectedCategory('');
    setCategoryId(null);
  };

  const generateSlug = (name) => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)+/g, '');
  };

  return (
    <div className="product-form-container">
      <h1 className="form-title">Add New Product</h1>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}

      <form className="product-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Product Name:</label>
          <input
            type="text"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Price:</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Details:</label>
          <textarea
            value={details}
            onChange={(e) => {
              const value = e.target.value;
              if (!/\d/.test(value)) {
                setDetails(value);
              }
            }}
            required
          />
        </div>
        <div className="form-group">
          <label>Location:</label>
          <input
            value={location}
            onChange={(e) => {
              const value = e.target.value;
              if (!/\d/.test(value)) {
                setLocation(value);
              }
            }}
            required
          />
        </div>
        <div className="form-group">
          <label>Category:</label>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            required
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Images:</label>
          <input type="file" multiple onChange={handleImageChange} />
          <div className="image-preview">
            {images.map((image, index) => (
              <img
                key={index}
                src={URL.createObjectURL(image)}
                alt="Preview"
                className="preview-image"
              />
            ))}
          </div>
        </div>
        <button className="submit-button" type="submit" disabled={isLoading}>
          {isLoading ? 'Adding Product...' : 'Add Product'}
        </button>
      </form>
    </div>
  );
};

export default ProductForm;
