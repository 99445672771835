import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineMinus, AiOutlinePlus, AiFillStar, AiOutlineStar, AiOutlineShopping } from 'react-icons/ai';
import { useStateContext } from '../context/StateContext';
import { urlFor } from '../sanity/sanityClient';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SlugProducts = ({ slugCategory, handleImageSelect }) => {
  const { onAdd } = useStateContext();

  const [productQuantities, setProductQuantities] = useState(() =>
    slugCategory.reduce((acc, product) => {
      acc[product._id] = 1;
      return acc;
    }, {})
  );

  const handleQuantityChange = (productId, type) => {
    setProductQuantities((prevQuantities) => {
      const updatedQuantities = { ...prevQuantities };
      if (type === 'increment') {
        updatedQuantities[productId] = (updatedQuantities[productId] || 0) + 1;
      } else if (type === 'decrement' && updatedQuantities[productId] > 1) {
        updatedQuantities[productId] -= 1;
      }
      return updatedQuantities;
    });
  };

  const handleAddToCart = (product) => {
    const focusedImage = product.image[product.currentImageIndex];
    const productWithFocusedImage = { ...product, image: [focusedImage] };
    const productQty = productQuantities[product._id] || 1;

    onAdd(productWithFocusedImage, productQty);

    toast.success(`${productQty} ${product.name} added to the cart.`, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

 const handleScrollToTop=()=>{
   window.scrollTo({
    top:0,
    behavior:'smooth',
   });
  };
  
  const firstRowProducts = slugCategory.slice(0, 4);
  const remainingProducts = slugCategory.slice(4);

  return (
    <div style={{ padding: '10px', maxWidth: '1200px', margin: '0 auto' }}>
      <ToastContainer />

      {/* First row with responsive full-width overflow on small screens */}
      <div
        style={{
          display: 'flex',
          gap: '20px',
          overflowX: 'auto',
          paddingBottom: '20px',
        }}
      >
        {firstRowProducts.map((product) => (
          <div
            key={product._id}
            
            style={{
              flex: '0 0 calc(25% - 20px)', // Default for large screens
              maxWidth: 'calc(25% - 20px)',
              border: '1px solid #ddd',
              borderRadius: '8px',
              padding: '15px',
              textAlign: 'center',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.3s ease',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
            onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          >
          <Link
              to={`/category/${product.slug.current}`}
              style={{ textDecoration: 'none', color: 'black' }}
            >
            
            <img
              src={urlFor(product.image[product.currentImageIndex])}
              alt={product.name}
              style={{
                width: '100%',
                maxWidth: '200px',
                height: 'auto',
                objectFit: 'cover',
                borderRadius: '8px',
                marginBottom: '10px',
              }}
              onClick={handleScrollToTop}
            />
            <p >Price: <strong>TZS {product.price.toLocaleString()}/=</strong></p>

            <button
              style={{
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                padding: '10px 15px',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '16px',
              }}
              onClick={() => handleAddToCart(product)}
            >
              Add to <AiOutlineShopping />
            </button>
            </Link>
          </div>
        ))}

      </div>

      {/* Remaining products with responsive grid */}
      <div
        style={{
          display: 'grid',
          gap: '20px',
          padding: '20px 0',
          gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        }}
      >
        {remainingProducts.map((product) => (
          <div
            key={product._id}
            style={{
              border: '1px solid #ddd',
              borderRadius: '8px',
              padding: '15px',
              textAlign: 'center',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.3s ease',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
            onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          >
            <h3>{product.name}</h3>
            <img
              src={urlFor(product.image[product.currentImageIndex])}
              alt={product.name}
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                borderRadius: '8px',
                marginBottom: '10px',
              }}
            />
           
             <div
              style={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'center',
                marginTop: '10px',
                overflowX: 'auto',
              }}
            >
              {product.image.map((img, index) => (
                <img
                  key={index}
                  src={urlFor(img)}
                  alt={`Thumbnail ${index + 1}`}
                  style={{
                    width: '50px',
                    height: '50px',
                    objectFit: 'cover',
                    border: index === product.currentImageIndex ? '2px solid #000' : '2px solid transparent',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleImageSelect(product._id, index)}
                />
              ))}
            </div>
            <p>Price: <strong>TZS {product.price.toLocaleString()}/=</strong></p>
            <p>{product.details}</p>
          <div style={{ margin: '15px 0' }}>
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiOutlineStar />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '15px 0',
            }}
          >
            <span
              style={{ cursor: 'pointer', margin: '0 10px' }}
              onClick={() => handleQuantityChange(product._id, 'decrement')}
            >
              <AiOutlineMinus />
            </span>
            <span>{productQuantities[product._id] || 1}</span> {/* Fallback to 1 */}
            <span
              style={{ cursor: 'pointer', margin: '0 10px' }}
              onClick={() => handleQuantityChange(product._id, 'increment')}
            >
              <AiOutlinePlus />
            </span>
            </div>
            <button
              style={{
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                padding: '10px 15px',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '16px',
              }}
              onClick={() => handleAddToCart(product)}
            >
              Add to <AiOutlineShopping />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SlugProducts;
