import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCYWAslP6saTETxpIeorz0S4MNwCOxodqU",
  authDomain: "brija-c77c8.firebaseapp.com",
  projectId: "brija-c77c8",
  storageBucket: "brija-c77c8.appspot.com",
  messagingSenderId: "194287624843",
  appId: "1:194287624843:web:25524894184d5d9462ea8e",
};

// VAPID Key
const vapidKey =
  "BCYYXt1yhekVq4dgop8qt3kl3634qUoQg2SJqEfr6vqOBIFOGdizbhvL-dCiGOLxD-RO9lVOhRy2s41W5FX8Rxc";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

/**
 * Get the current FCM token.
 * @returns {Promise<string>} The current token if successful.
 */
export const getCurrentToken = async () => {
  try {
    const currentToken = await getToken(messaging, { vapidKey });
    if (!currentToken) {
      throw new Error("Failed to generate the registration token.");
    }
    console.log("Client Token:", currentToken);
    return currentToken;
  } catch (error) {
    console.error("Error getting current FCM token:", error);
    throw error;
  }
};

/**
 * Listener for incoming messages.
 * @returns {Promise<object>} Resolves with the message payload.
 */
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("Message received:", payload);
      resolve(payload);
    });
  });

/**
 * Request user permission for notifications and retrieve the FCM token.
 * @returns {Promise<string>} The FCM token if permission is granted.
 */
export const Sendrequest = async () => {
  console.log("Requesting user permission for notifications...");

  try {
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      throw new Error("User permission denied.");
    }

    console.log("Notification user permission granted.");
    const token = await getToken(messaging, { vapidKey });

    if (!token) {
      throw new Error("Failed to generate the registration token.");
    }

    console.log("FCM Token:", token);
    return token;
  } catch (error) {
    console.error("Error during permission request or token retrieval:", error);
    throw error;
  }
};
