import React, { useState, useEffect } from 'react';
import { Link, useNavigate  } from 'react-router-dom';
import axios from "axios";
import CategoriesDisplay from './CategoriesDisplay';
import Cart from './Cart';
import { useStateContext } from '../context/StateContext';
import { AiOutlineShopping } from 'react-icons/ai';
import { FiMenu } from 'react-icons/fi';
import { GoSearch, GoArrowLeft } from 'react-icons/go';
import writer from '../assets/writer.svg';
import logo192 from './logo192.jpg';
import Amazon from './Amazon.png';
import Loader from './Loader';
import SearchForm from './SearchForm';
import UploadForm from "./UploadForm";
import { Sendrequest, onMessageListener } from '../firebase';
import {
  allowedusers
} from "../utils/APIRoutes";

import Aliexpress from './Aliexpress.png';
import './App.css';

const Categories = ({ categories, isLoading, changeCategory, currentUser, showCntcts, handleNearbyClick,
         fetchTraderProducts, nearBy, isTrader, traderProducts
 }) => {
  const navigate = useNavigate();
  const { showCart, setShowCart, totalQuantities } = useStateContext();
  const [name, setName] = useState('BridgenApp');
  const [searching, setSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [allowedUsers, setAllowedUsers]=useState([]);
  const [isBrand, setIsBrand]=useState(false);
  

  // Fetch allowed users
  useEffect(()=>{
      const fetchAllowedUsers = async () => {
          try {
            const {data} = await axios.get(allowedusers);
            setAllowedUsers(data.map(user => user._id));
          } catch (error) {
            console.error("Error fetching requested users:", error);
          }
        };
        fetchAllowedUsers();
  }, [])
  
   const isUserAllowed = currentUser?._id && allowedUsers.includes(currentUser._id);

   const handleProtectedClick = (path) => {
    if (!currentUser) {
      navigate("/login");
    } else {
      navigate(path);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        await Sendrequest();
        const payload = await onMessageListener();
        console.log({
          title: payload?.title,
          body: payload?.body,
        });
      } catch (err) {
        console.error('Failed:', err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (searchQuery.length > 0) {
      const filteredResults = categories.filter((category) =>
        category.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setSearchResults(filteredResults);
      setSearching(true);
    } else {
      setSearchResults([]);
      setSearching(false);
    }
  }, [categories, searchQuery]);

  const goBrand=()=>{
    setIsBrand(!isBrand)
  }
   
   const handleReload=()=>{
    window.location.reload();
  }

  return (
    <div className="app-container" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
      <div className="market" style={{ width: '100%', backgroundColor: '#00000076' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative', textAlign: 'right', top: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
          <div style={{ color: 'white', height: '20px' }} onClick={showCntcts}><FiMenu /></div>
                <span>
            <button
              onClick={() =>
                handleProtectedClick(
                  isUserAllowed
                    ? `/addproduct/${currentUser?._id}`
                    : `/userrequests/${currentUser?._id}`
                )
              }
              style={{
                background: "none",
                border: "none",
                color: "white",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Sell Product
            </button>
          </span>

          {currentUser?._id==="657d60c2e11554ed0ddbdc31" && <Link to={`/userrequests/${currentUser._id}`} style={{ color: 'black', textDecoration: 'none' }}>
                    Verify
                  </Link>}
          <button onClick={goBrand} style={{ height: '20px' }}>{isBrand?(< GoArrowLeft/>):("Brands")}</button>
          {nearBy?( <button onClick={handleReload} style={{ height: '20px', textDecoration: 'none' }}><GoArrowLeft /></button>):(<button onClick={handleNearbyClick}  style={{ height: '20px' }}>Nearby</button>)}

          <div className="installContainer">
            <button id="butInstall" style={{ display: 'none' }}>Install App</button>
          </div>
          <button type="button" className="cart-icon" onClick={() => setShowCart(true)}>
            <AiOutlineShopping />
            <span className="cart-item-qty">{totalQuantities}</span>
          </button>
          <div className={`modal ${showCart ? 'active' : ''}`}><Cart /></div>
        </div>
        <br />
        <div className="search-form" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '85%', marginLeft: '8%', borderRadius: '10px', backgroundColor: '#f0f0f0', border: 'solid' }}>
          <div><GoSearch /></div>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            required
            placeholder={`Search ${name} items`}
            style={{ width: '80%', height: '20px', borderRadius: '5px', border: 'none', outline: 'none' }}
          />
        </div>
        <br />
        {searching && <SearchForm searchResults={searchResults} />}
      </div>

      {isBrand?(<UploadForm  fetchTraderProducts={fetchTraderProducts}
                isTrader={isTrader}
                traderProducts={traderProducts} currentUser={currentUser}/>):
      (<div className="search-results">
        {isLoading && categories.length <= 0 ? (
          <Loader />
        ) : !isLoading && categories.length <= 0 ? (
          <div>
            <img src={writer} width={300} height={300} alt="no-internet" />
            <h5>No Products</h5>
            <p style={{ display: 'flex' }}>Check another category or trader</p>
          </div>
        ) : (
          <CategoriesDisplay categories={categories} />
        )}
      </div>)}
      
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <img src={logo192} style={{ width: 30, height: 20, padding: 10, borderRadius: 10, marginTop: 0 }} alt="BridgeApp_logo" onClick={() => setName('BridgenApp')} />
        <img src={Amazon} style={{ width: 40, height: 20, padding: 10, borderRadius: 10, marginTop: 0 }} alt="Amazon_logo" onClick={() => setName('(Not enabled)')} />
        <img src={Aliexpress} style={{ width: 40, height: 20, padding: 10, borderRadius: 10, margin: 0 }} alt='Aliexpress_logo' onClick={() => setName('(Not yet)')} />
      </div>
      <div className="footer" style={{ textAlign: 'center' }}>
        <p>Copyright © 2024 BridgenApp.</p>
      </div>
    </div>
  );
};

export default Categories;
