import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { GoHome } from 'react-icons/go';
import { AiOutlineShopping } from 'react-icons/ai';
import { client } from '../sanity/sanityClient';
import { useStateContext } from '../context/StateContext';
import Cart from './Cart';
import NavBar from './NavBar';
import FetchCategory from './FetchCategory';
import ProductDetail from './ProductDetail';
import SlugProducts from './SlugProducts';
import './Products.css';
import 'react-toastify/dist/ReactToastify.css';

const Products = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [slugCategory, setSlugCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [showCategory, setShowCategory] = useState(true);
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const { totalQuantities, showCart, setShowCart } = useStateContext();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await client.fetch(
          '*[_type in [  "grocery", "beauty", "hotels", "estate", "clothingandfashion", "kitchen", "crops", "electronics", "jewelryandaccessories", "salon", "foodandbeverages", "sports",  "arts", "medical", "vehicles", "fashion"] && slug.current == $slug]',
          { slug }
        );
        setProducts(data);
        if (data.length > 0) {
          const category = data[0]._type;
          const categoryProducts = await client.fetch(`*[_type == $category]`, { category });
          setSlugCategory(categoryProducts.map((product) => ({
            ...product,
            currentImageIndex: 0,
            quantity: 1,
          })));
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchProducts();
  }, [slug]);

  const handleImageSelect = (productId, index) => {
    setSlugCategory((prev) =>
      prev.map((product) =>
        product._id === productId ? { ...product, currentImageIndex: index } : product
      )
    );
  };

  const handleFetchCategory = async (category) => {
    setShowCategory(false);
    try {
      const data = await client.fetch(`*[_type == '${category}']`);
      setSubCategories(data);
    } catch (error) {
      console.error(error);
    }
    navigate(`/category/${category}`);
  };

  const toggleMobileDropdown = () => {
    setShowMobileDropdown(!showMobileDropdown);
  };



  return (
    <div className="productsPage" style={{ textAlign: 'center' }}>
      <div className="NavPan" style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'relative',
        zIndex: '1000',
        top: '10px',
        paddingRight: '20px',
        backgroundColor: '#00000076',
        borderRadius: '10px',
      }}>
        <h3 style={{ color: 'white', left: '0' }}>
          <Link to={'/'} style={{ color: 'white', textDecoration: 'none' }}><GoHome />Home</Link>
        </h3>
        <div style={{ display: 'flex' }}>
          <h3><Link to="#" style={{ color: 'white', textDecoration: 'none' }}>Unboxing</Link></h3>
        </div>
        <button
          type="button"
          className="cart-icon"
          onClick={() => setShowCart(true)}
          style={{ right: '0' }}
        >
          <AiOutlineShopping />
          <span className="cart-item-qty">{totalQuantities}</span>
        </button>
        <div className={`modal ${showCart ? 'active' : ''}`}><Cart /></div>
        <div className={`modalTwo ${showMobileDropdown ? 'active' : ''}`}>
          <NavBar handleFetchCategory={handleFetchCategory} toggleMobileDropdown={toggleMobileDropdown} />
        </div>
      </div>

      <div className="productsDisplay">
        {showCategory ? (
          <ProductDetail products={products} />
        ) : (
          <FetchCategory subCategories={subCategories} />
        )}
      </div>

      <SlugProducts slugCategory={slugCategory} handleImageSelect={handleImageSelect}/>
    </div>
  );
};

export default Products;
