import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { urlFor } from '../sanity/sanityClient';

const CategoriesDisplay =({categories})=>{

	return (

          <div className="categories-grid">
            {categories.map(category => (
              <div key={category._id} className="result-item">
                <Link
                  to={`/category/${category.slug.current}`}
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  <div className="category-content">
                  {category.soldout? <span style={{position:'absolute', top:5, left:0, backgroundColor:'rgba(0,0,0,0.5)', 
                  color:'red', padding:'5px', borderRadius:'5px'}}>{category.soldout}</span>:''}
                  {category.discount? <span style={{position:'absolute', top:5, left:200,whiteSpace:'nowrap', backgroundColor:'rgba(0,0,0,0.5)', 
                  color:'white', padding:'5px', borderRadius:'5px'}}>{category.discount}</span>:''}
                    {category.image[0] && (
                      <LazyLoadImage
                        src={urlFor(category.image[0])}
                        width={250}
                        height={250}
                        alt={category.name}
                        effect="blur"
                        style={{ borderRadius: '30px', padding: '10px' }}
                      />
                    )}
                    <div className="result-display">
                      <p style={{ fontSize: 'small', color: 'red', backgroundColor:'white' }}>
                        Tzs:<strong>{category.price.toLocaleString()}/=</strong>
                      </p>
                       <p style={{ fontFamily: 'arial', whiteSpace:'nowrap', backgroundColor:'pink', borderRadius:'5px' }}>{category.name}  <br />
                      <small style={{backgroundColor:'white',}}> {category.location}</small></p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
		);
};

export default CategoriesDisplay;