import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  allowUser,
  rejectUser,
  requestingusers,
  requesttosell,
} from "../utils/APIRoutes";

const UserRequests = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [requestedUsers, setRequestedUsers] = useState([]);
  const [error, setError] = useState("");

  // Fetch and cache country data
  useEffect(() => {
    const fetchCountries = async () => {
      const cachedData = sessionStorage.getItem("countryData");
      if (cachedData) {
        setCountries(JSON.parse(cachedData));
      } else {
        try {
          const response = await axios.get("https://restcountries.com/v3.1/all");
          const countryData = response.data.map((country) => ({
            name: country.name.common,
            code: country.cca2,
            phoneCode:
              country.idd.root && country.idd.suffixes
                ? country.idd.root + country.idd.suffixes[0]
                : "",
          }));
          sessionStorage.setItem("countryData", JSON.stringify(countryData));
          setCountries(countryData);
        } catch (err) {
          setError("Failed to fetch country data");
          console.error(error);
        }
      }
    };

    fetchCountries();
  }, [error]);

  // Sort countries alphabetically
  const sortedCountries = [...countries].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const handleCountryChange = (e) => {
    const selected = e.target.value;
    setSelectedCountry(selected);
    const country = countries.find((c) => c.name === selected);
    setPhoneCode(country?.phoneCode || "");
  };

  // Fetch requested users
  const fetchRequestedUsers = async () => {
    try {
      const { data } = await axios.get(requestingusers);
      setRequestedUsers(data);
    } catch (error) {
      console.error("Error fetching requested users:", error);
    }
  };

  // Handle request submission
  const handleRequestSubmit = async (e) => {
    e.preventDefault();

    if (!phoneNumber.match(/^\d{6,15}$/)) {
      alert("Please enter a valid phone number (6-15 digits).");
      return;
    }

    if (address.trim() === "") {
      alert("Address cannot be empty.");
      return;
    }

    try {
      const { data } = await axios.post(requesttosell, {
        phoneNumber: `${phoneCode}${phoneNumber}`,
        address,
        userId,
        selectedCountry,
      });
      alert(data.message);
      fetchRequestedUsers(); // Refresh the list
      navigate("/");
    } catch (error) {
      console.error("Error submitting request:", error);
    }
  };


  // Handle allow user
  const handleAllowUser = async (id) => {
    try {
      const {data}= await axios.post(allowUser, {id});
      alert(data.message);
      fetchRequestedUsers(); // Refresh the list
    } catch (error) {
      console.error("Error allowing user:", error);
    }
  };

  // Fetch users on component mount
  useEffect(() => {
    fetchRequestedUsers();
  }, []);


  // Handle allow user
  const handleRejectUser = async (id) => {
    try {
      const {data}= await axios.post(rejectUser, {id});
      alert(data.message);
      fetchRequestedUsers(); // Refresh the list
    } catch (error) {
      console.error("Error allowing user:", error);
    }
  };

  // Fetch users on component mount
  useEffect(() => {
    fetchRequestedUsers();
  }, []);

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", maxWidth: "800px", margin: "0 auto" }}>
      <h1 style={{ textAlign: "center", color: "#333" }}>Send Request</h1>

      {/* Form for phone number and address */}
      <form
        onSubmit={handleRequestSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          background: "#f9f9f9",
          padding: "15px",
          borderRadius: "8px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <label htmlFor="country" style={{ fontWeight: "bold" }}>Country:</label>
          <select
            id="country"
            value={selectedCountry}
            onChange={handleCountryChange}
            required
            style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }}
          >
            <option value="" disabled>Select a country</option>
            {sortedCountries.map((country) => (
              <option key={country.code} value={country.name}>
                {country.name}
              </option>
            ))}
          </select>
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <label htmlFor="phoneNumber" style={{ fontWeight: "bold" }}>Phone Number:</label>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <span>{phoneCode}</span>
            <input
              type="number"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc", flex: "1" }}
            />
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <label htmlFor="address" style={{ fontWeight: "bold" }}>Address:</label>
          <input
            type="text"
            id="address"
            value={address}
            placeholder="Region/Mkoa"
            onChange={(e) => setAddress(e.target.value)}
            required
            style={{ padding: "8px", borderRadius: "4px", border: "1px solid #ccc" }}
          />
        </div>

        <button
          type="submit"
          style={{
            padding: "10px",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            alignSelf: "flex-end",
          }}
        >
          Submit Request
        </button>
      </form>

      <hr style={{ margin: "20px 0" }} />

      {/* List of requested users */}
      <h2 style={{ color: "#555" }}>Requested Users</h2>
      {userId==="657d60c2e11554ed0ddbdc31" && requestedUsers.length > 0 ? (
        <ul style={{ listStyle: "none", padding: 0 }}>
          {requestedUsers.map((user) => (
            <li
              key={user._id}
              style={{
                background: "#f1f1f1",
                marginBottom: "10px",
                padding: "10px",
                borderRadius: "6px",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <p style={{ margin: "0" }}><strong>Username:</strong> {user.username}</p>
              <p style={{ margin: "0" }}><strong>Email:</strong> {user.email}</p>
              <p style={{ margin: "0" }}><strong>Phone:</strong> {user.phoneNumber}</p>
              <p style={{ margin: "0" }}><strong>Location:</strong> {user.address}</p>
              <p style={{ margin: "0" }}><strong>Country:</strong> {user.country}</p>
              {!user.allowed ? (
                <button
                  onClick={() => handleAllowUser(user._id)}
                  style={{
                    padding: "8px",
                    backgroundColor: "#007BFF",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    alignSelf: "flex-start"
                  }}
                >
                  Allow
                </button>
              ) : (
                <button
                  onClick={() => handleRejectUser(user._id)}
                  style={{
                    padding: "8px",
                    backgroundColor: "#FF6347",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    alignSelf: "flex-start"
                  }}
                >
                  Reject
                </button>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <p style={{ color: "#777" }}>No requests found.</p>
      )}
    </div>
  );
};

export default UserRequests;
